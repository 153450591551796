<template>
  <div class="mgm--wrapper">
    <h3 class="title">Die Top Empfehlung</h3>

    <p>Freunde werben Freunde lohnt sich für beide!</p>

    <p>
      Empfehlen Sie {{ $brand.name }} einem Freund und Sie bekommen beide 5 €
      Bonus gutgeschrieben
    </p>

    <ImageComponent
      class="mgm-image--wrapper"
      :src="`/assets/webp_images/mgm/mgm_${$brand.code}.webp`"
      :alt="imageAlt"
      :width="$breakpoint.fromDesktop() ? 317 : 250"
      :height="$breakpoint.fromDesktop() ? 195 : 154"
    />

    <ButtonComponent
      class="mgm--button"
      button-type="primary"
      :href="$router.resolve(path).href"
      :clickhandler="() => $router.push(path)"
    >
      Zum Angebot
    </ButtonComponent>
  </div>
</template>

<script>
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import ImageComponent from 'src/components/common/image/image.vue'

export default {
  name: 'PortalMgmComponent',
  components: {
    ButtonComponent,
    ImageComponent
  },
  data() {
    return {
      path: { name: 'SelfcareMgm' },
      imageAlt: `Empfehlen Sie ${this.$brand.name} einem Freund und Sie bekommen beide 5 € Bonus gutgeschrieben`
    }
  }
}
</script>

<style lang="scss" scoped>
.mgm--wrapper {
  @include font_normal;

  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px 16px;
  background-color: var(--background-secondary);

  .subtitle {
    margin-bottom: 20px;
  }

  @include breakpoint($from-tablet) {
    padding: 40px 16px;
    flex-basis: 50%;
  }

  @include breakpoint($from-desktop) {
    font-size: 1.2rem;
    flex-basis: 35%;
  }
}

.mgm--button {
  margin-top: 20px;
}

.mgm-image--wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    align-self: center;
    max-width: 100%;
  }
}
</style>
